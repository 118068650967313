<script>
    import Button, {Label, Icon} from '@smui/button';
	import LinearProgress from '@smui/linear-progress';

	import getLocalIP from './ip-resolv.js';
	import { addDevice, clearAllDevices } from '../device/device-manager.js'

	let ownIP = "x.x.x.x";
	let scanInProgress = false;

	let scanCount = {
		current: 0,
		total: 252,
	};


	let DeviceScanner = {
	  init: (ip) => {
		ownIP = ip;
		DeviceScanner.scanAll();
	  },

	  updateScanCount: (shouldIncrease = false) => {
	    if (shouldIncrease == true) {
	      scanCount.current++;
	    }

	    if (scanCount.current == scanCount.total) {
		  scanInProgress = false;
		  scanCount.current = 0;
	    }
	  },


	  scanAll: () => {
		scanInProgress = true;
		clearAllDevices();

		let lastIpBlock = ownIP.substring(ownIP.lastIndexOf(".")+1);
		let baseIp = ownIP.replace(lastIpBlock, "");

		scanCount.current = 0;
		DeviceScanner.updateScanCount(false);

		for (let i = 2; i < 255; i++) {
			if (i == lastIpBlock) { continue; }
			let testIP = baseIp + i;
			DeviceScanner.scanSingleIp(testIP);
		}

	  },
	  scanSingleIp: (ip) => {
	    const scanPromise = new Promise((resolve, reject) => {
		  fetch("http://" + ip + "/version")
			.then((response) => { return response.json()})
			.then((versionJSON) => { 
				if (versionJSON.device == "ESP_home_light") {
					versionJSON.ip = ip
					resolve(versionJSON); 
				} else {
					reject(ip);
				}
			})
	        .catch((error) => { reject(ip); });
	    });

	    scanPromise.then((versionJSONWithIP) => {
	      //console.log("FOUND home-light device", ip);		  
		  DeviceScanner.updateScanCount(true);
		  addDevice(versionJSONWithIP);
	    }).catch((ip) => {
	      // this IP is not running a home-light
	      // console.log("Scanned, but not a home-light device", ip);
	      DeviceScanner.updateScanCount(true);
	    });
	  }
	};

	getLocalIP(DeviceScanner.init, true);
</script>

<div class="device-scan-container">
  <h3>Device scan</h3>  
  <p>
	You can scan the local network for <nobr>home-light</nobr> devices (scans last IP block 1-254).
  </p>
  <p>
	Your IP: {ownIP}
  </p>
  <div>
		<Button on:click={DeviceScanner.scanAll} disabled='{scanInProgress}' variant="outlined">
		  <Icon class="material-icons">settings_remote</Icon>
		  <Label>Scan for devices</Label>
		</Button>

		{#if scanInProgress}
		    <div>Scanning .. {scanCount.current}/{scanCount.total}</div>
			<LinearProgress indeterminate />
		{/if}

	</div>
</div>


<style>
.device-scan-container {
	margin-top: 40px;
	padding: 15px 10px;
	border-top: 1px solid #bbb;
}
</style>