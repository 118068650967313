<script>
  import { devices } from '../stores.js';

  import Card, {Content, PrimaryAction, Media, MediaContent, Actions, ActionButtons, ActionIcons} from '@smui/card';
  import Button, {Label} from '@smui/button';
  import IconButton, {Icon} from '@smui/icon-button';
  import LinearProgress from '@smui/linear-progress';

  import { createDevice } from './device-manager.js'

  export let deviceConf = createDevice({ip:"0.0.0.0", version:"load"});
  let isExpanded = false;
  
  const MODE_SINGLE = "SINGLECOLOR"
  const MODE_PHASE = "SWAP2COLORS"

  let modes = [
	{ val: MODE_SINGLE, text: `Single color` },
	{ val: MODE_PHASE, text: `Color phasing` }
  ];

  const presets = [
	  {
		  label: "Candle",
		  conf: {color1: "140b01", mode: MODE_SINGLE}
	  },
	  {
		  label: "Candle phase",
		  conf: {color1: "140b01", color2: "180902", mode: MODE_PHASE, time: 20}
	  },	  
  ];

  function sendSettings() {
	  let url = "http://" + deviceConf.ip + "/settings"
	  url += "?p=" + deviceConf.settings.power
	  url += "&c=" + deviceConf.settings.color1
	  url += "&c2=" + deviceConf.settings.color2
	  url += "&m=" + deviceConf.settings.mode
	  url += "&t=" + deviceConf.settings.time
	  fetch(url)
		.then((response) => { return response.json()})
		.then((settingsNew) => { console.log("settingsNew", settingsNew); })
  }

  function toggleExpanded() {
	  isExpanded = !isExpanded;
  }

  function togglePower(ev) {
	  deviceConf.settings.power = deviceConf.settings.power == "0" ? "1" : "0";
	  sendSettings();
  }
  function setMode(ev) {
	  deviceConf.settings.mode = ev.target.value;
	  sendSettings();
  }
  function setBaseColor(ev) {
	  deviceConf.settings.color1 = ev.target.value.replace("#", "");
	  sendSettings();
  }
  function setSecondColor(ev) {
	  deviceConf.settings.color2 = ev.target.value.replace("#", "");
	  sendSettings();
  }
  function setPhaseTime(ev) {
	  deviceConf.settings.time = ev.target.value;
	  sendSettings();
  }


  function updateFromPreset(presetConf) {
	  for (const key in presetConf) {
		  deviceConf.settings[key] = presetConf[key];
	  }
	  sendSettings();
  }


  let mediaStyle = ""
  $: if (deviceConf.settings.mode == "SWAP2COLORS") {
		mediaStyle = "background: linear-gradient(90deg, #"+deviceConf.settings.color1+" 0%, #"+deviceConf.settings.color2+" 100%);"
	 } else {
		mediaStyle = "background-color: #"+deviceConf.settings.color1
	 }
  
</script>

<div class="device-card">
<Card>	
  <PrimaryAction on:click={() => toggleExpanded()}>
	<Media style={mediaStyle}>
		<div class="expand-icon-box">
			<Icon class="material-icons">{isExpanded ? "expand_less" : "expand_more"}</Icon>
		</div>
	</Media>		
  </PrimaryAction>

	<Content class="mdc-typography--body2">
		<h2 class="mdc-typography--headline6" on:click={() => togglePower()} style="margin: 0;">
			{#if deviceConf.settings.power == "1" }
				<Icon class="material-icons" style="color: orange;">emoji_objects</Icon>
			{:else}
				<Icon class="material-icons">emoji_objects</Icon>
			{/if}
			IP: {deviceConf.ip}
		</h2>

		{#if isExpanded}
			<br/>
			<h3 class="mdc-typography--subtitle2" style="margin: 0 0 10px; color: #888;">
				<select on:change="{setMode}" class="mode-select">
					{#each modes as mode}
						<option value={mode.val} selected={deviceConf.settings.mode == mode.val}>
							{mode.text}
						</option>
					{/each}
				</select>
			</h3>		

			{#if deviceConf.version == "load"}
				<div>Loading settings ..</div>
				<LinearProgress indeterminate />
			{:else}
				<input type="color" value="#{deviceConf.settings.color1}" name="c" class="colorpicker" on:change="{setBaseColor}"/>
				{#if deviceConf.settings.mode == "SWAP2COLORS" }
					<input type="color" value="#{deviceConf.settings.color2}" name="c2" class="colorpicker" on:change="{setSecondColor}"/>
					<br/><br/>
					<div>
						<Icon class="material-icons">watch_later</Icon>
						Phase time: {deviceConf.settings.time}s
						<br/>
						<input type="range" value="{deviceConf.settings.time}" min="1" max="120" on:change="{setPhaseTime}"/>
					</div>
				{/if}

				<h5>Presets</h5>
				<ul class="presets-list">
					{#each Object.values(presets) as preset}
					<li><Button on:click={() => updateFromPreset(preset.conf)}>{preset.label}</Button></li>
					{/each}
				</ul>


			{/if}
		

			<div class="device-card-footer">
				<div>Version: {deviceConf.version}</div>
			</div>

		{/if}
	</Content>
</Card>
</div>

<br/>



<style>
.expand-icon-box {
	float: right;
	background-color: #fff;
    vertical-align: middle;
}

.mode-select {
	width: 100%;
}
input[type=color] {
	width: 49%;
	height: 70px;
}
input[type=range] {
	width: 100%;
	height: 30px;
}

.device-card-footer {
	margin-top: 10px;
	color: #999;
	font-size: .8em;
}
</style>