<script>
  import { devices } from '../stores.js';
  import Device from './Device.svelte';
</script>


<div class="devices-container">
  {#each Object.values($devices) as deviceConf, i}
    <Device deviceConf={deviceConf} />
  {:else}
    <div>No devices available.</div>
  {/each}
</div>


<style>
.devices-container { padding: 10px; }
</style>